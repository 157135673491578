import * as fuzzball from "fuzzball";

const $searchInput = $(".search-input input");
const $searchButton = $(".search-input button");
const $dropdown = $(".autocomplete-dropdown");
const $faqsMain = $(".faqs-main");
const $allFAQs = $(".accordions_container .accordion");

// Function to filter FAQs
function filterFAQs(query) {
  const results = getMatchingFAQs(query);

  // Clear existing FAQs
  $faqsMain.empty();

  // Create a new wrapper div with the class "faqs_container"
  const $wrapper = $("<div>").addClass("faqs_container");

  // Append matching FAQs to the wrapper
  results.forEach(({ faq }) => {
    $wrapper.append($(faq).clone());
  });

  // Append the wrapper to the faqs-main container
  $faqsMain.append($wrapper);
}

// Function to get matching FAQs using Fuzzball
function getMatchingFAQs(query) {
  if (!query.trim()) return [];

  const results = [];
  $allFAQs.each((_, faq) => {
    const $faq = $(faq);
    const title = $faq.find(".accordion_title").text().trim();
    const score = fuzzball.partial_ratio(query, title); // Fuzzball scoring
    if (score > 70) {
      // Adjust threshold as needed
      results.push({ faq, score, title });
    }
  });

  // Sort results by score
  return results.sort((a, b) => b.score - a.score);
}

// Function to update dropdown suggestions
function updateDropdown(query) {
  const results = getMatchingFAQs(query);

  // Clear dropdown
  $dropdown.empty().toggle(results.length > 0);

  // Add results to dropdown
  results.forEach(({ title }) => {
    const $item = $("<div>").addClass("autocomplete-item").text(title);

    // Handle click on dropdown item
    $item.on("click", () => {
      $searchInput.val(title);
      filterFAQs(title); // Filter FAQs based on the selected suggestion
      $dropdown.hide();
    });

    $dropdown.append($item);
  });
}

// Event listener for typing in the search input
$searchInput.on("input", () => {
  const query = $searchInput.val();
  updateDropdown(query);
});

// Event listener for clicking the search button
$searchButton.on("click", () => {
  const query = $searchInput.val();
  filterFAQs(query);
});

// Close dropdown if clicked outside
$(document).on("click", (e) => {
  if (!$(e.target).closest(".search-input").length) {
    $dropdown.hide();
  }
});
